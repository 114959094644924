.modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    outline: none;
}
.modal-children{
    outline: none;
}
@media only screen and (min-width: 767px) {
    .modal-children {
        width: 50%;
    }
}
