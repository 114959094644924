.header {
  padding-bottom: 8px;

  &--logo {
    img {
      width: 100%;
      max-width: 100px;
    }
  }

  &--dropdown {
    position: absolute;
    top: 3.45rem;
    right: 1.25rem;
    max-width: 25rem;
    min-height: 2rem;
    height: auto;
    z-index: 100;
    background: white;

    &-profile-select {
      padding-left: 1em;
    }

  }

  &--logout {
    padding-top: 25px
  }
}

// move next float list to standalone

.float-list {
  overflow-y: auto;
  max-height: 400px;
  min-height: 150px;
  .float-list-empty {
    line-height: 150px;
  }
}

.card-body:hover {
  background-color: #f3f3f3;
}
.notification {
  background-color: #f5fcff;
}
.notification:hover {
  background-color: #eef4f7;
}
.notification-footer {
  display: grid;
}

.navbar-logo {
  width:2.5em;
}
