.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

body {
    font-family: "Roboto", sans-serif !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.relativeLoader {
    position: absolute;
    top: 0;
    margin: 0 auto;
    left: 0;
    width: 100%;
    height: 80%;
    padding-top: 20%;
    background: rgba(255, 255, 255, .65);
    z-index: 40;
}

.globalLoader {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15em;
    height: 100vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    transition: all linear .15s;
}

.globalLoaderFork {
    opacity: 0;
    position: absolute;
    top: 4em;
    left: 0;
    padding-top: 15em;
    height: 200vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.55);
    transition: all linear .1s;

}

.custom-file-label::after {
    content: 'Загрузить';
}

.MuiAccordionDetails-root {
    display: block !important;
}
