.demo-wrapper {
    height: 40vh;
    width: 100%;
}

.demo-editor {
    border: 2px solid black;
}

.news-hide-toolbar {
    display: none;
}

.btn-container {
    margin-top: 150px;
}

.news-editor {
    position: absolute;
    overflow: hidden;
    z-index: 999999999999999;
}

.demo-editor {
    width: 100%;
}

.root-card {
    min-height: 150px;
}
