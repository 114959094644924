.chat-container--video {
  max-height: 576px;
}
.chat-container--video #incoming {
  width: 100%;
}
.chat-container--video #outcoming {
  position: relative;
  width: 100px;
  height: 100px;
  top: -115px;
  left: 20px;
}
