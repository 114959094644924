.preview-image-file {
  & img {
    max-width: 100%;
    margin-top: 1rem;
  }

  & small {
    cursor: pointer;
    text-decoration: underline;
  }
}
