.filter .list-group-item {
  display: inherit;
}
.filter .list-group-item .filter-label {
  width: 100%;
  padding-right: 8px;
}
.filter .react-datepicker-wrapper {
  width: auto;
}
.filter .react-datepicker-wrapper input {
  width: 100%;
  padding: 0 24px 0 6px;
}
.filter .list-group-item {
  display: block;
}
@media (min-width: 640px) {
  .filter .react-datepicker-wrapper input {
    width: 204px;
  }
  .filter .list-group-item {
    display: inherit;
  }
}

.filter .react-datepicker-wrapper .react-datepicker__close-icon::after {
  color: black;
  background: unset;
  font-size: 14px;
}
